var mid_slider_settings = {
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	centerMode: false,
	focusOnSelect: true,
	mobileFirst: true,
	infinite: true,
	arrows: false,
};

var resumeslider1_options = {
	infinite: true,
	speed: 600,
	centerMode: true,
	variableWidth: true,
	centerPadding: "60px",
	slidesToShow: 3,
	cssEase: "linear",
	prevArrow: "<button id='sample-prev1' class='sampleprev-btn sample-circle'><i class='far fa-arrow-left' aria-hidden='true'></i></button>",
	nextArrow: "<button id='sample-next1' class='samplenext-btn sample-circle'><i class='far fa-arrow-right' aria-hidden='true'></i></button>",

	responsive: [
		{
			breakpoint: 1024,
			settings: {
				centerMode: true,
				centerPadding: "30px",
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 767,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: "20px",
				slidesToShow: 1,
				dots: true,
			},
		},
	],
};

var resumeslider2_options = {
	infinite: true,
	speed: 600,
	centerMode: true,
	variableWidth: true,
	centerPadding: "60px",
	slidesToShow: 3,
	cssEase: "linear",
	prevArrow: "<button id='sample-prev2' class='sampleprev-btn sample-circle'><i class='far fa-arrow-left' aria-hidden='true'></i></button>",
	nextArrow: "<button id='sample-next2' class='samplenext-btn sample-circle'><i class='far fa-arrow-right' aria-hidden='true'></i></button>",
	dots: true,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: "30px",
				slidesToShow: 3,
				dots: true,
			},
		},
		{
			breakpoint: 767,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: "20px",
				slidesToShow: 1,
				dots: true,
			},
		},
	],
};

jQuery(document).ready(function (e) {
	var wv = $(window).width();
	if (wv <= 767) {
		$(".midd-section-layout").not(".slick-initialized").slick(mid_slider_settings);
	}
	$("#resumeSlider1").not(".slick-initialized").slick(resumeslider1_options);
	$("#resumeSlider2").not(".slick-initialized").slick(resumeslider2_options);
});

$(window).resize(function () {
	var wv = $(window).width();
	if ($("#resumeSlider1").hasClass("slick-initialized")) {
		$("#resumeSlider1").slick("refresh");
	}
	if ($("#resumeSlider2").hasClass("slick-initialized")) {
		$("#resumeSlider2").slick("refresh");
	}

	if (wv > 767) {
		if ($(".midd-section-layout").hasClass("slick-initialized")) {
			$(".midd-section-layout").slick("unslick");
		}
	} else {
		if ($(".midd-section-layout").hasClass("slick-initialized")) {
			$(".midd-section-layout").slick("refresh");
		} else {
			$(".midd-section-layout").slick(mid_slider_settings);
		}
	}
});

function load_missed_lazy_images() {
	var missedLazyloadImages = document.querySelectorAll("#resumeSlider1 .lazy, #resumeSlider2 .lazy, .midd-section-layout .lazy");
	if(missedLazyloadImages != '' && missedLazyloadImages != null) {
	missedLazyloadImages.forEach(function (img) {
		img.src = img.dataset.src;
		img.classList.remove("lazy");
		img.removeAttribute("loading");
		img.classList.remove("lazy-hidden");
		img.classList.add("lazy-loaded");
	});
} }

$(document).on("click", ".accordian-checkbox", function () {
	$(".accordian-checkbox").not(this).prop("checked", false);
});

var checkLazyLoad = true;

// Function to handle lazy loading
function lazyLoadOnScroll() {
    if(checkLazyLoad) {
        var imgs = document.getElementsByClassName("lazy-onscrolled"); 
        for(var x = 0; x < imgs.length; x++) { 
            var item = imgs[x];
            if ((item.tagName).toLowerCase() == 'picture') {
                var sources = item.querySelectorAll('source');
                if(sources != '' && sources != null) {
                    sources.forEach(function(source) {
                        var src = source.dataset.srcset;
                        if(src != '' && src != null) {
                            source.srcset = src;
                            source.removeAttribute('data-srcset');
                        }
                    });
                }
                var imgElem = item.querySelector('img');
                var src = imgElem.getAttribute('data-orig_src');
                if(src != '' && src != null) {
                    imgElem.src = src;
                    imgElem.removeAttribute('data-orig_src');
                }
            } else { 
                var src = item.getAttribute('data-orig_src');
                if(src != '' && src != null) {
                    item.setAttribute('src',src);
                    item.removeAttribute('data-orig_src');
                    item.classList.remove("lazy-onscrolled");
                }
            }
        }
        checkLazyLoad = false;
    }
}

// Event listener for scroll
window.addEventListener('scroll', lazyLoadOnScroll);

/**
             * Lazy load some specific images here
             */
	
/*function recheck() {
	var window_top = $(this).scrollTop();
	var window_height = $(this).height();
	var view_port_s = window_top;
	var view_port_e = window_top + window_height;
	$(".animatedSection").each(function () {
		var block = $(this);
		var block_top = block.offset().top;
		var block_height = block.height();

		if (block_top <= view_port_e) {
			block.addClass("fadeInUp");
		} else {
			block.removeClass("fadeInUp");
		}
	});
}*/

/*$(function () {
	$(window).scroll(function () {
		recheck();
	});

	$(window).resize(function () {
		recheck();
	});

	recheck();
	var windowWidth = $(window).width();
	var currentDiv = $("#animateBox1,#animateBoxHead1");
	var nextDiv,
		count = 1;
	var timesRun = 0;

	var myInterval = setInterval(function () {
		if (count == 4) {
			currentDiv.hide();
			currentDiv.removeClass("outAnimation");
			currentDiv = $("#animateBox1,#animateBoxHead1");
			currentDiv.show();
			currentDiv.addClass("outAnimation");
			count = 1;
		} else {
			count++;
			currentDiv.hide();
			currentDiv.removeClass("outAnimation");
			currentDiv = currentDiv.next();
			currentDiv.show();
			currentDiv.addClass("outAnimation");
		}
	}, 5000);

	
});*/
